import { z } from "zod";

export const helloSignConfigSchema = z.object({
  isEmbeddedFlowEnabled: z.boolean(),
  templateId: z.string(),
  fallbackStaticUrl: z.string(),
  instructions: z.string(),
});

export const compositeConfigSchema = z.object({
  requirements: z.array(
    z.object({
      ctaText: z.string(),
      requirementId: z.string(),
    })
  ),
  instructions: z.string(),
});

export type HelloSignConfig = z.infer<typeof helloSignConfigSchema>;
export type CompositeConfig = z.infer<typeof compositeConfigSchema>;
