import { z } from "zod";

import { Resources } from "../constants";
import { hcpDocumentAttributesSchema } from "../hcp-documents/types";
import { HelloSignWorkflowSessionStatus } from "./constants";

export const getHelloSignSessionQueryParamsSchema = z.object({
  include: z.literal(Resources.HCP_DOCUMENTS).optional(),
});

export type GetHelloSignSessionQueryParams = z.infer<typeof getHelloSignSessionQueryParamsSchema>;

export const helloSignSessionAttributesSchema = z.object({
  signUrl: z.string().optional(),
  status: z.nativeEnum(HelloSignWorkflowSessionStatus),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type HelloSignSessionAttributes = z.infer<typeof helloSignSessionAttributesSchema>;

export const helloSignSessionResponseSchema = z.object({
  data: z.object({
    type: z.literal(Resources.HELLO_SIGN_SESSIONS),
    attributes: helloSignSessionAttributesSchema,
    id: z.string(),
    relationships: z.object({
      document: z
        .object({
          data: z.object({
            id: z.string(),
            type: z.literal(Resources.HCP_DOCUMENTS),
          }),
        })
        .optional(),
      requirement: z.object({
        data: z.object({
          id: z.string(),
          type: z.literal(Resources.REQUIREMENTS),
        }),
      }),
    }),
  }),
  included: z
    .array(
      z.object({
        type: z.literal(Resources.HCP_DOCUMENTS),
        id: z.string(),
        attributes: hcpDocumentAttributesSchema,
      })
    )
    .optional(),
});

export type HelloSignSessionResponse = z.infer<typeof helloSignSessionResponseSchema>;
