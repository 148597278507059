import { DocumentStatus } from "@src/appV2/Accounts/Documents/types";
import { z } from "zod";

const notificationDataAttributesSchema = z.object({
  reason: z.string().optional(),
  note: z.string().optional(),
  pushNotification: z.boolean().optional(),
});

export const hcpDocumentAttributesSchema = z.object({
  hcpId: z.string(),
  requirementId: z.string(),
  uploadedForRequirementId: z.string(),
  status: z.nativeEnum(DocumentStatus),
  expiry: z.string().datetime().optional(),
  name: z.string().min(1),
  displayName: z.string().optional(),
  issueDate: z.string().datetime().optional(),
  archived: z.boolean(),
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  fileStorageRootFolder: z.string().optional(),
  fileStorageFileKey: z.string().optional(),
  subType: z.string().optional(),
  notification: notificationDataAttributesSchema.optional(),
});
